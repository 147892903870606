<script>
  import { _, Auth, user, contract } from "../stores/index";
  import { UsersRepository } from "../repositories/index";

  import { Button, Flex } from "@gl/design-system";
  import { onMount, getContext } from "svelte";
  import { navigate } from "svelte-routing";

  const authClass = new Auth(user);
  const { isAuthorized } = authClass;

  const visibleApps = [
    { id: "access-manager", requiredPolicy: "user_read" },
    { id: "channel-manager", requiredPolicy: "channel_access" },
    { id: "cut-manager", requiredPolicy: "cut_access" },
    { id: "content-manager", requiredPolicy: "" },
  ];

  let cardApps = [];

  let showCards;
  const userApps = async () => {
    cardApps = $user.apps.filter((app) =>
      visibleApps.some(
        (va) => va.id === app.id && $isAuthorized(va.requiredPolicy)
      )
    );
  };

  onMount(() => {
    userApps();
  });
</script>

<div class="p-6">
  <h1 class="gl-title level-3 mb-5">
    {$_("home.welcome")}
    {getContext("config").NAME}
  </h1>

  {#if $user && $user.apps}
    <div class="grid">
      {#if cardApps.length > 0}
        {#each cardApps as app}
          <div class="card mr-5 gl-card flex-grow-1">
            <h1 class="gl-title mb-4 level-3">
              {$_(`${app.id}.card.title`)}
            </h1>
            <p class="mb-5 text">{$_(`${app.id}.card.text`)}</p>

            <Button
              on:click={() => navigate(`/${app.id}/contracts/${$contract.id}/`)}
            >
              {$_(`${app.id}.card.button`)}
            </Button>
          </div>
        {/each}
      {:else}
        <div class="card mr-5 gl-card flex-grow-1">
          <h1 class="gl-title mb-4 level-3">
            {$_("home.empty.title")}
          </h1>
          <p class="mb-5 text">{$_("home.empty.text")}</p>
        </div>
      {/if}
    </div>
  {/if}
</div>

<style>
  .card {
    display: flex;
    padding: 60px 150px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .grid {
    display: grid;
    grid-template-columns: auto auto;
    gap: 20px;
  }

  .text {
    text-align: center;
  }
</style>
