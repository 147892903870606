<script>
  import CutManager from "@gl/cut-manager";
  import { setContext, getContext } from "svelte";
  import { user, language, contract, callout, Auth } from "./stores/index";
  import { UsersRepository } from "./repositories/index";
  let config = getContext("config");

  const authClass = new Auth(user, "cut-manager");
  setContext("isAuthorized", authClass);
</script>

{#if $contract}
  <CutManager
    apiurl={config.API_URL}
    user={$user}
    language={$language}
    contract={$contract}
    basepath={"/cut-manager"}
    repositories={{
      getUsers: UsersRepository.getUsers.bind(this, $contract.id),
      getGroups: UsersRepository.getGroups.bind(this, $contract.id),
    }}
    on:callout={(event) => callout.set(event.detail.callout)}
  />
{/if}
